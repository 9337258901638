<template>
  <div>
    <div class="input-wrapper">
      <div class="option"><label>Options</label></div>
      <div><label class="default">Default</label></div>
    </div>
    <b-row v-for="(choice, index) in item.field_choice" :key="index">
      <b-col class="input-wrapper mb-2">
        <div class="sequence">
          <div>{{ index + 1 }}</div>
        </div>

        <div class="input">
          <InputText
            class="mb-0"
            textFloat=""
            v-model="choice.name"
            :placeholder="choice.name"
            :disabled="!isEditable || [1, 2, 3, 4].includes(choice.id)"
            type="text"
          />
        </div>

        <b-form-checkbox
          :id="`checkbox-question-default-${index}`"
          :name="`checkbox-question-default-${index}`"
          v-model="choice.isDefault"
          :value="1"
          :unchecked-value="0"
          class="mb-0"
          @change="SelectIsDefault(index, $event)"
        ></b-form-checkbox>

        <b-icon
          v-if="(isEditable && !isDisable) || ![1, 2, 3, 4].includes(choice.id)"
          size="lg"
          icon="trash-fill"
          class="icon-size pointer"
          @click="onDeleteChoice(item, index)"
        />
      </b-col>
    </b-row>
    <b-row v-if="isEditable">
      <b-col sm="6" class="mt-3 d-flex align-items-center">
        <span class="mr-4">{{ (item?.field_choice?.length || 0) + 1 }}</span
        ><span
          class="text-underline pointer"
          @click="addChoice(item.field_choice)"
          >Add Option</span
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
export default {
  name: "FieldChoiceDetail",
  components: {
    InputText,
  },
  props: {
    item: {
      required: true,
      type: Object,
    },
    isEditable: {
      default: true,
      type: Boolean,
    },
    isDisable: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      isLoading: true,
      isBusy: false,
    };
  },
  methods: {
    deleteChoice(index) {
      if (!this.isEditable) return;
      this.item.field_choice.splice(index, 1);
    },
    addChoice(item) {
      if (!this.isEditable) return;
      let len = item.length + 1;
      item.push({
        name: "choice " + len,
        sort_order: item.length,
        isDefault: 0,
      });
    },
    async SelectIsDefault(index, val) {
      if (this.item.field_type_id != 2) {
        this.item.field_choice.forEach((c, i) => {
          if (i == index) {
            c.isDefault = val || 0;
          } else {
            c.isDefault = 0;
          }
        });
      }
    },
    onDeleteChoice(item, index) {
      item.field_choice.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-size {
  font-size: 20px;
  color: #575757;
}
.icon-size.disabled {
  color: #bababa;
}
.input-wrapper {
  display: flex;
  align-items: center;
  gap: 24px;

  .option {
    width: calc(50% + 29px);
  }

  .sequence {
    width: 20px;
  }

  .input {
    width: 50%;
  }
}
</style>
